import { Col, Row } from 'antd';
import { H2, H2Bold, TextRegular } from '../components/shared/typography';
import {
  HomePage,
  ISimpleSection,
} from '../components/shared/contentfu.interface';
import { Link, graphql } from 'gatsby';
import { SiteLayout, StyledContent } from '../components/layout/layout';
import { css, keyframes } from '@emotion/react';

import { AiOutlineArrowRight } from 'react-icons/ai';
import FadeInContainer from '../components/shared/sectionAnimation';
import Img from 'gatsby-image';
import { PageContext } from '../pageContext';
import React from 'react';
import ReactPlayer from 'react-player';
import Seo from '../components/Seo';
import styled from '@emotion/styled';
import { theme } from '../components/shared/theme';

const TriangleLeft = styled.div`
  width: 100%;
  overflow: hidden;
  top: -180px;
  position: relative;
  z-index: 3;

  ::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-left: 50vw solid white;
    border-right: 50vw solid white;
  }
`;

const fadeIn = keyframes`
  from {
    transform: scale(1.1);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const HeroSection = styled.div`
  width: 100%;
  height: calc(100vh + 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
  padding: 150px 0;
  position: relative;
  top: -80px;
  overflow: hidden;
`;

const HeroContainer = styled.div`
  max-width: 1200px;
  animation: ${fadeOut} 2.5s;
  position: relative;
  z-index: 10;
  width: 100%;
`;

const StyledImgContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + 100px);
  left: 0;
  top: 0;
  animation: ${fadeIn} 2s;
  z-index: 1;
`;

const HeroInner = styled.div`
  margin-left: 10%;
  max-width: 400px;
`;

const StyledSection = styled.div`
  color: ${theme.colors.txtLight.black};
  align-self: center;
  padding: 4rem;
  p {
    font-size: 16px;
  }
  h3 {
    width: 100%;
    font-size: 24px;
    line-height: 32px;
    color: ${theme.colors.txtLight.black};
  }

  @media (max-width: 700px) {
    padding: 1rem;
    padding-top: 0;
  }
  `;


const StyledColImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const StyledSectionImg = styled(Img)`
  height: 500px;
  object-fit: scale-down;
  object-position: center;
  width: auto;

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    }
  
`;

const ImgL = css`
    height: 500px;
  object-fit: scale-down;
  object-position: center;
  width: auto;

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    }
  
`
const LinkCSS = styled(Link)`
  padding: 0 4rem;
  @media (max-width: 700px) {
    padding: 0 1rem;
  }
`

type Props = {
  data: {
    allContentfulHomePage: {
      nodes: HomePage[];
    };
  };
  pageContext: PageContext;
};

const Index: React.FC<Props> = ({ pageContext, data }: Props) => {
  const content = data.allContentfulHomePage.nodes[0];
  const contentfulSections: ISimpleSection[] =
    data.allContentfulHomePage.nodes[0].sections;

  const StyledImg = styled(Img)`
    min-width: 100%;
    min-height: calc(100% + 100px);
  `;

  return (
    <SiteLayout
      locale={pageContext.locale}
      componentName={pageContext.componentName}
    >
      <Seo
        title="Specialister  i AIA og ADK alarmanlæg"
        image={'/HighView-Security-text-tlf.jpg'}
        lang={pageContext.locale}
        keywords={["AIA", "ADK", "Privat", "Sikkerhed", "IT Sikkerhed", "NOX alarmanlæg", "Jablotron JA100", "HighView", "Overvågning", "Erhvers sikkerhed", "adgangskontrolanlæg" ]}
        pathname="/"
      />
      <HeroSection>
        <HeroContainer>
          <HeroInner>
            <H2Bold>{content.title}</H2Bold>
            <TextRegular style={{ color: theme.colors.txtLight.white }}>
              {content.subtitle}
            </TextRegular>
          </HeroInner>
        </HeroContainer>
        <StyledImgContainer>
          <StyledImg
            fluid={content.coverImg.fluid}
            alt={content.coverImg.title}
          />
        </StyledImgContainer>
      </HeroSection>
      <TriangleLeft />
      <StyledContent>
      <Row gutter={[0,24]} style={{ marginBottom: '2rem', padding: '1rem' }}>
        <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
            <H2>{content.introHeadline}</H2>
            <TextRegular>{content.introText.introText}</TextRegular>
            <Link to={'/kontakt'}>
              Kontakt os{' '}
              <AiOutlineArrowRight
                size={16}
                style={{ top: '2px', position: 'relative' }}
              />
            </Link>
          </Col>
          <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}}>
            <StyledColImg src="../../logo.png" />
          </Col>
        </Row>
        {contentfulSections &&
          contentfulSections.map((s: ISimpleSection, key: number) => {
            return (
              <FadeInContainer
                transition={key % 2 == 0 ? 'XL' : 'XR'}
                key={key}
              >
                <Row
                  style={{ background: theme.colors.brandLight.bg }}
                  gutter={[20, 20]}
                >
                  <Col
                    style={{ padding: '1rem' }}
                    xs={{ span: 24, order: 1 }}
                    md={{ span: 12, order: key % 2 == 0 ? 1 : 0 }}
                  >
                    <StyledSection
                      dangerouslySetInnerHTML={{
                        __html: s.section.childContentfulRichText.html,
                      }}
                    />
                    <LinkCSS to={'/kontakt'}>
                      Kontakt os{' '}
                      <AiOutlineArrowRight
                        size={16}
                        style={{ top: '2px', position: 'relative' }}
                      />
                    </LinkCSS>
                  </Col>
                  <Col
                    style={{ padding: '1rem' }}
                    xs={{ span: 24, order: 0 }}
                    md={{ span: 12, order: key % 2 == 0 ? 0 : 1 }}
                  >
                    <StyledSectionImg fluid={s.img.fluid} alt={s.img.title} imgStyle={ImgL} />
                  </Col>
                </Row>
              </FadeInContainer>
            );
          })}
        <Row justify={'center'} style={{ paddingTop: '3rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
          <ReactPlayer url="https://www.youtube.com/watch?v=m7eY1H204pU" />
        </Row>
      </StyledContent>
    </SiteLayout>
  );
};
export default Index;

export const query = graphql`
  query HomePageQuery {
    allContentfulHomePage {
      nodes {
        title
        subtitle
        introHeadline
        coverImg {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
        introText {
          introText
        }
        sections {
          section {
            childContentfulRichText {
              html
            }
          }
          img {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
